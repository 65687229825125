body {
  background: $white;;
  font-family: 'PT Sans', sans-serif;
  color: #333;
}

.row {
  max-width: 1450px;


  &:nth-child(even) {
      background: gray;
  }
}

main {
  .int-page & {
    padding: 40px 0;
  }
}

.row-info {
  padding-bottom: 50px;
  padding-top: 30px;

  .columns {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media #{$medium-up} {
    padding-bottom: 100px;
    padding-top: 50px;
  }
}

.row--full {
  max-width: 100%;
}

.flexbox-container {
  display: -ms-flex;
	display: -webkit-flex;
  display: flex;
  
  &--medium-up {
    @media screen and (max-width: 1024px) {
      display: inline-block !important;
    }
  }
}

.content-block {

  @media #{$medium-up} {
    padding: 50px 0;    
  }
  
  &:nth-child(even) {
    background: rgba(163,152,130,.14);
  }

  .columns {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

a {
  @include transition(.2s);
  text-decoration: none;
}

sup {
  font-size: 50%;
}