.list {
    margin: 0;
    
    &__item {
        
    }

    &--member-benefits {        
        .list__item {
            font-size: 25px;
            font-weight: 600;
            line-height: 30px;
            margin: 0 0 43px;

            @media #{$medium-up} {
                font-size: 29px;
                line-height: 39px;
            }
        }

    }
}