.hero {
  background: $blue;
  overflow: hidden;
  padding: 25px 10px;
  position: relative;

  @media #{$medium-up} {
    padding: 15px 10px;     
  }

  .background-dots {
    @include transition(.8s, 2.5s);
    background: transparent url(../images/hero-bg.png);
    background-position: center 0px;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 600px;
    width: 100%;
    max-width: 1450px;
    margin: 0 auto;
    position: absolute;
    top: 65px;
    right: 0;
    left: 0;
    opacity: 0;
  }

  .columns {
    margin-top: -25px;
  }
  
  img {
    max-width: 900px;
    position: relative;
    top: 25px;
    width: 100%;
  }

  &__image, &__text {
    position: relative;
    z-index: 2;
  }

  &__image {
    display: block;

    @media #{$large-up} {
      display: none;
    }
  }

  &__text {
    display: none;
    max-width: 70%;
    overflow: hidden;
    padding: 75px 0;

    @media #{$large-up} {
      display: block;
    }
  }

  &__phello, &__opportunities, &__rise, &__99 {
    position: relative;
    top: 900px;
  }

  &__phello {
    @include transition(.7s, .2s, ease-in-out);
    background: transparent url('../images/hero-text-phello.png') 0 0 no-repeat;
    background-size: 100%;
    width: 100%;
    max-width: 571px;
    height: 163px;
    display: block;
  }

  &__opportunities {
    @include transition(.7s, 1s, ease-in-out);    
    background: transparent url('../images/hero-text-opportunities.png') 0 0 no-repeat;
    background-size: 100%;
    width: 100%;
    max-width: 1308px;
    height: 220px;
    display: block;
  }

  &__rise {
    @include transition(.7s, 1.5s, ease-in-out);    
    background: transparent url('../images/hero-text-rise.png') 0 0 no-repeat;
    background-size: 100%;
    width: 25%;
    max-width: 383px;
    height: 181px;
    display: block;
    float: left;
  }

  &__99 {
    @include transition(.7s, 2s, ease-in-out);    
    background: transparent url('../images/hero-text-99.png') 0 0 no-repeat;
    background-size: 100%;
    width: 75%;
    max-width: 907px;
    height: 169px;
    display: block;
    float: left;
  }

  &.scrolled {
    .background-dots {
      opacity: 1;
    }

    .hero__phello, .hero__opportunities, .hero__rise, .hero__99 {
      top: 0;
    }  
  }
}

.hero {
  &--int {
    padding: 70px 0 50px;

    .row {
      background: none; 
    }
    
    p {
      color: $white;
    }    
  }  
}