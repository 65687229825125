footer {
  background: $brown;
  overflow: auto;
  padding: 30px 0 10px;

  @media #{$medium-up} {
    padding: 100px 0 40px;
  }

  .logo {
    height: 43px;
    margin: 50px 0 15px;
    width: 102px;

    @media #{$medium-up} {
      height: 83px;
      margin: 100px 0 25px;
      width: 292px;
    }
  }

  p {
    font-size: 20px;

    @media #{$medium-up} {
      font-size: 30px;
    }
  }

  a {
    @include transition(.2s);
    color: $white;
    font-weight: 600;
    &:hover {
      color: $gray;
    }
  }
}