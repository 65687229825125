header {
  background: $blue;
  padding: 20px 0;

  @media #{$medium-up} {
    padding: 40px 0;
  }

  .logo {
    display: none;
    float: left;
    height: 37px;
    margin-right: 30px;
    width: 129px;
  }

  nav {
    float: left;
  }

  .int-page & {
    .logo {
      display: block;
    }
  }
}