.button {
    color: $white;
    font-family: inherit;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin: 10px 0;

    @media #{$medium-up} {
        font-size: 30px;
        line-height: 30px;
    }
    
    &--block {
        display: block;
    }

    &--inline {
        display: inline;
    }

    &--inline-block {
        display: inline-block;
    }

    &--orange {
        background: $orange;
    }

    &--blue {
        background: $blue;
    }

    &--small {
        padding: 10px;
    }

    &--medium {
        padding: 25px 10px;
    }

    &--rounded {
        border-radius: 10px;
    }

    &--border-blue-light {
        border: 10px solid $blue-light;
    }

    &:hover {
        background: $white;
        color: $blue;
    }
}