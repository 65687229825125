$bar-width: 100%;
$bar-height: 5px;
$bar-spacing: 15px;
.hamburger-menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: $bar-height + $bar-spacing*2;
  cursor: pointer;
  z-index: 13;

  .int-page & {
    top: 0;
  }
  @media (max-width: 986px) {
    display: block;
  }
}

.bar,
.bar:after,
.bar:before {
  width: $bar-width;
  height: $bar-height;
}

.bar {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(255, 255, 255, 1);
  transition: all 0ms 300ms;
  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(255, 255, 255, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  ;
}

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  ;
}
