#membership-levels {
    padding: 40px 0 15px;

    @media #{$large-up} {
        padding: 114px 0 45px;
    }

    .button {
        position: relative;
        top: -12px;

        @media #{$large-up} {
            margin: 0 0 0 20px;            
        }
    }
}

#member-benefits {
    padding: 40px 0 15px;

    @media #{$medium-up} {
        padding: 42px 0 150px;        
    }
}

.content__icons {    
    margin: 50px 0 0;
    
    @media #{$large-up} {
        margin: 150px 0 0;        
    }

    img {
        margin: 0 0 20px;
        max-width: 50%;

        @media #{$large-up} {
            margin: 0;
            max-width: 90%;            
        }
    }
    p {
        @media #{$small-only} {
            font-size: 18px;
            line-height: 20px;    
        }
        margin-top: 50px;
        margin-left: 20px;
    }
}