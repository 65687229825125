h1, h2, h3, h4 {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
}

p {
  color: $gray;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}

p + iframe {
  margin-top: 20px;
}

.title {
  &__heading {
    color: $white;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;

    @media #{$medium-up} {
      font-size: 75px;      
      line-height: 75px;      
    }
  }

  &__subheading {
    color: $gray;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;

    @media #{$medium-up} {
      font-size: 35px;
      line-height: 40px;
    }
  }

  &__blurb {
    font-size: 15px;
    font-weight: 100;

    @include mq($min-width: 1025px, $max-width: 1335px) {
      position: absolute;
      bottom: 10px;
      width: 90%;
      line-height: 25px;
      font-size: 20px;

      br {
        display: none;
      }
    }

    @media #{$medium-up} {
      font-size: 25px;
    }
  }
}
