$lightblue: #0680FA;
$blue: #192F58;
$blue-light: #0680FA;
$yellow: #F1FA06; 
$yellow2: #F8D303;
$white: #FFFFFF;
$orange: #EA6841;
$gray: #848483;
$brown: #52544E;
$black: #272727;

$imgpath: '../images/';