.h-text-orange, .h-link-orange {
  color: $orange !important;
}

.h-text-white {
  color: $white;
}

.h-link-orange {
  &:hover, &:active, &:visited {
    color: $orange;
  }
}

.h-font-30 {
  font-size: 30px;
}

.h-font-bold {
  font-weight: 600;
}

.h-display-inline {
  display: inline-block;
}

.h-color-blue {
  color: $blue;
}

.h-nowrap {
  white-space: nowrap;
}

.h-hover-white {
  &:hover {
    color: $white;
  }
}

.h-text-yellow {
  color: $yellow !important;
}

.h-text-yellow2 {
  color: $yellow2 !important; 
}

.h-text-blue-light {
  color: $blue-light;
}

.h-inline-block {
  display: inline-block;
}

.h-o {
  position: relative;
  &:before {
    background: $orange;
    content: '';
    height: 10px;
    left: -15px;
    margin: 0 30px;
    position: absolute;
    right: 0;
    top: 20px;
    width: 50%;

    @media #{$medium-up} {
      left: 0;
      top: 60px;
    }

    @media #{$large-up} {
      height: 20px;
      top: 50px;
    }
  }

  &--alt {
    &:before {
      left: -23px;
      top: 5px;

      @media #{$medium-up} {
        left: -12px;
        top: 30px;          
      }

      @media #{$large-up} {
        left: 0;
        top: 50px;
      }
    }
  }

  &--offset-left {
    margin-left: -25px;

    @media #{$medium-up} {
      margin-left: -30px;
    }

    @media #{$large-up} {
      margin-left: -50px;
    }
  }

  &--offset-right {
    margin-right: -15px;

    @media #{$medium-up} {
      margin-right: -30px;
    }

    @media #{$large-up} {
      margin-right: -50px;
    }
  }
}

.h-background-yellow {
  background: rgba(241, 250, 6, .8);
}

.h-padding-100-15-0 {
  padding: 30px 15px 100px;

  @media #{$medium-up} {
    padding: 100px 15px 0;
  }
}

.h-padding-25-15 {
  padding: 25px 15px;
}

.h-no-padding {
  padding: 0 !important;
}