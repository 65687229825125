.background-membership-levels {
    background: transparent url('../images/membership-levels-bg.png') right 0 no-repeat;
    background-size: cover;
}

.background-membership-benefits {
    background: transparent url('../images/membership-benefits-mobile.png') 100% 0 no-repeat;
    background-size: cover;    

    @media #{$large-up} {
        background: transparent url('../images/even-bigger.jpg') 100% bottom no-repeat;     
        background-size: cover;   
    }
}

.background-image--kurt {

    @media #{$medium-up} {
        padding-bottom: 100px !important;;        
        padding-top: 100px !important;;                
    }

    @media #{$large-up} {
        padding-bottom: 0 !important;;        
        padding-top: 0 !important;;                
    }
}