nav {
  &.footer-navigation {
    li {
      display: inline-block;
      &:first-child {
        margin-right: 95px;
      }
    }
    a {
      color: $orange;
      font-size: 20px;

      @media #{$medium-up} {
        font-size: 35px;
      }

      &:hover {
        color: $white;
      }
    }
  }    
}