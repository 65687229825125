.table {
    border: 0;
    margin: 40px 0 0;

    @media #{$medium-up} {
        display: inherit;
    }
    
    &--border {
        background: transparent;

        td {
            border: 1px solid $white;
            color: $yellow;
            font-size: 12px;
            font-weight: 600;
            padding: 5px 0px 5px 5px;
            text-align: center;

            @media #{$medium-up} {
                font-size: 25px;
                padding: 30px 10px;                
            }

            &:first-child {
                color: $white;
                text-align: left;
            }
        }

        th {
            border: 1px solid $white;
            color: $white;
            font-size: 14px;
            height: 130px;            
            line-height: 17px;
            text-align: center;
            vertical-align: middle;        
            
            @media #{$medium-up} {
                font-size: 24px;
                line-height: 32px;                
            }

            &:first-child {
                font-weight: 100;
                font-style: italic;
                vertical-align: bottom;
            }
        }

        td, th, thead, tr {
            background: transparent !important;
        }

        .title {
            color: $yellow;
            font-size: 14px;

            @media #{$medium-up} {
                font-size: 27px;                              
            }
        }
    }
}