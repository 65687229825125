nav {
    &.main-navigation {
        position: relative;
        top: 12px;

        @media (max-width: 986px) {
            @include transition;
            position: absolute;
            top: -320px;
            width: 100%;
            background: $blue;
            left: 0;
            padding: 20px 0;
            z-index: 12;

            .nav-open & {
                top: -20px;
            }
        }

        li {
            display: inline-block;

            @media (max-width: 986px) {
                width: 100%;
                text-align: center;
            }

            &:first-child {
                a {
                    padding: 0;
                }
            }
        }
        a {
            @include transition(.2s);
            color: $white;
            font-size: 20px;
            font-weight: 600;
            padding: 0 0 0 25px;

            @media (max-width: 986px) {
                padding: 0;
            }

            &:hover {
                color: $orange;
            }

            &.member-login {
                color: $yellow;
            }
         }
    }    
}
