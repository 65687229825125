.logo {
  background: transparent url($imgpath + 'logo.png') 0 0 no-repeat;
  background-size: 100%;
  max-width: 292px;

  a {
    display: block;
    height: inherit;
    text-indent: -8400px;
    width: inherit;
  }
}