.testimonial {
    &__avatar {

    }

    &__content {
        font-size: 30px;

        p {
            margin: 0;
        }
    }
    
    &__title {
        color: $blue;
    }

    &__text {
        color: $blue-light;
    }

    &__author, &__link {
        font-size: 30px;
        font-style: italic;
        font-weight: 100;
    }

    &__author {
        color: $blue-light;
        padding: 30px 0 0;
    }

    &__link {
        color: $orange;
        text-decoration: none;
    }
}

.testimonial + a {
    display: block;
    margin-top: 100px;
}